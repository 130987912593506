<template>
    <div class="new-team_wrap">
        <van-cell-group inset :border="false">
            <van-cell
                title="所属工作室"
                required
                :value="getName(org)"
                is-link
                @click="showSelector('org')"
            />

            <van-cell
                title="主咨询师"
                required
                center
                :value="getName(master)"
                is-link
                @click="showSelector('master')"
            />

            <van-cell
                title="团队成员"
                required
                center
                :value="getName(consultant)"
                is-link
                @click="showSelector('consultant')"
            />

            <van-cell required title="选择阶段" :border="false">
                <van-radio-group v-model="stage" direction="horizontal" class="flex-end">
                    <van-radio :class="`radio-item ${stage == 1 && 'isChecked'}`" :name="1">
                        破冰
                    </van-radio>
                    <van-radio :class="`radio-item ${stage == 2 && 'isChecked'}`" :name="2">
                        一阶段
                    </van-radio>
                </van-radio-group>
                <div :class="['tips', { show: stage == 2 }]">
                    注意：直接选择一阶段无法退回到破冰阶段
                </div>
            </van-cell>
        </van-cell-group>

        <div class="btn-group">
            <van-button color="var(--secondMainColor)" @click="goBack"> 返回 </van-button>
            <van-button color="var(--mainColor)" :loading="submitting" @click="onSubmit">
                提交
            </van-button>
        </div>

        <!-- 选择工作室 -->
        <select-user
            :show="showOrgSelector"
            :isRadio="true"
            title="选择工作室"
            isSelectedWorkRoom
            formData="?showPeople=false"
            :selectedUser="org.map((item) => item.id)"
            @close="showOrgSelector = false"
            @confirm="onOrgConfirm"
        />

        <!-- 选择人员 -->
        <select-user
            :show="showUserSelector"
            v-bind="selectorOption"
            @close="showUserSelector = false"
            @confirm="onOrgConfirm"
        />
    </div>
</template>

<script>
import SelectUser from "@/components/SelectUser";
import { newTeam } from "@/api/hoss/team";
export default {
    components: { SelectUser },
    data() {
        return {
            org: [],
            master: [],
            consultant: [],
            stage: "",

            showOrgSelector: false,
            showUserSelector: false,
            selectorOption: null,

            rules: {
                org: [{ required: true, message: "请选择工作室" }],
                master: [{ required: true, message: "请选择主咨询师" }],
                consultant: [{ required: true, message: "请选择团队成员" }],
                stage: [
                    {
                        validator: () => !!this.stage,
                        message: "请选择阶段",
                    },
                ],
            },

            submitting: false,
        };
    },

    async created() {
        const { stage = "", clientId } = this.$route.query;
        this.stage = stage;
        this.clientId = clientId;
        const userInfo = await this.$getUserInfo();
        const { orgId: id, orgName: label } = userInfo;
        this.org = [{ id, label }];
    },
    methods: {
        onOrgConfirm(val) {
            if (this.selectorType == "org") {
                this.org = val;
            } else if (this.selectorType == "master") {
                this.master = val;
            } else {
                this.consultant = val;
            }
        },
        showSelector(type) {
            this.selectorType = type;
            if (type == "org") {
                return (this.showOrgSelector = true);
            } else if (type == "master") {
                this.selectorOption = {
                    title: "选择主咨询师",
                    isRadio: true,
                    selectedUser: this.master.map((item) => item.id),
                    disabledUser: this.consultant.map((item) => item.id),
                };
            } else {
                this.selectorOption = {
                    title: "选择团队成员",
                    isRadio: false,
                    selectedUser: this.consultant.map((item) => item.id),
                    disabledUser: this.master.map((item) => item.id),
                };
            }
            this.showUserSelector = true;
        },
        goBack() {
            this.$router.back(-1);
        },

        async onSubmit() {
            console.log("submit");
            const { consultant, master, org, stage } = this;
            if (!consultant.length || !master.length || !org.length || !stage) {
                return this.$toast("请完善团队信息");
            }
            this.submitting = true;
            try {
                const consultantList = this.consultant.map((item) => {
                    return {
                        memberId: item.id,
                        name: item.name,
                    };
                });
                const master = this.master.map((item) => {
                    return {
                        memberId: item.id,
                        name: item.name,
                    };
                });
                const [{ id: orgId, label: orgName }] = this.org;
                const { clientId, stage } = this;

                await newTeam({ clientId, consultantList, master, orgId, orgName, stage });
                this.goBack();
            } catch (error) {
                console.log(error);
            }
            this.submitting = false;
        },

        getName(arr) {
            return arr.map((item) => item.name || item.label).join("、");
        },
    },
};
</script>

<style lang="less" scoped>
.new-team_wrap {
    .van-cell-group {
        margin: 10px;
        border-radius: 10px;
        .van-cell__title {
            flex: 0 0 25%;
        }
        .van-cell__value {
            color: var(--titleColor);
        }
        .flex-end {
            justify-content: flex-end;
        }
    }

    .btn-group {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        .van-button {
            flex: 1 0 50%;
            border-radius: 0;
        }
    }

    .radio-item {
        font-size: 10px;
        position: relative;
        display: inline-flex;
        padding: 0px 8px;
        border-radius: 3px;
        background-color: var(--separatorColor);

        &.isChecked {
            color: #fff;
            background-color: var(--mainColor);
            /deep/.van-radio__label {
                color: #fff;
            }
        }

        /deep/.van-radio__icon {
            display: none;
        }
        /deep/.van-radio__label {
            margin-left: 0;
        }
    }

    .tips {
        text-align: right;
        font-size: 8px;
        color: var(--red);
        opacity: 0;
        line-height: 1em;
        margin: 8px 12px 0 0;
        white-space: nowrap;
        &.show {
            opacity: 1;
        }
    }
}
</style>
